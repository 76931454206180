<template>
  <v-card flat class="statistics">
    <v-row no-gutters v-if="openCloseTicketsLoading">
      <v-col v-for="n in 5" :key="n" cols="12">
        <v-skeleton-loader class="mx-auto" type="list-item-avatar"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else-if="!openCloseTicketsLoading" class="pa-0 mt-2"  :class="$vuetify.theme.dark ? 'dark-theme-chart' : ''">
      <v-col cols="12" lg="12" v-if="hasTicketPermission">
        <!-- <h5 class="pb-1">{{ $t('ticketsInfo') }}</h5> -->
        <highcharts :options="ticketChartOptions"></highcharts>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: ['hasTicketPermission', 'ticketChartOptions', 'openCloseTicketsLoading']
}
</script>
<style scoped>
 .highcharts-container {
   height: 343px !important;
 }
 #highcharts-6tykcu6-16 {
   height: 343px !important;
 }
</style>
